export const coloumns = [
  "Enquiry Status",
  "Date",
  "Category",
  "Supplier Name",
  "S. Code",
  "Description",
  "Pack",
  "Size",
  "ABV",
  "Type",
  "Variant",
  "Coded",
  "Btls Avail",
  "Cs Avail",
  "Price per Case(€)",
  "Price per Case($)",
  "Price per Case(£)",
  "Price per Bottle(€)",
  "Price per Bottle($)",
  "Price per Bottle(£)",
  "Status",
  "Delivery",
  "Location",
  "Actions"
  // "On Floor",
  // "Incoterms",
];

export const salsesColumns = [
  "Enquiry Status",
  "Date",
  "Category",
  "S. Code",
  "Description",
  "Pack",
  "Size",
  "ABV",
  "Type",
  "Variant",
  "Coded",
  "Btls Avail",
  "Cs Avail",
  "Price per Case(€)",
  "Price per Case($)",
  "Price per Case(£)",
  "Price per Bottle(€)",
  "Price per Bottle($)",
  "Price per Bottle(£)",
  "Status",
  "Delivery",
  "Location",
  // "On Floor",
  // "Incoterms",
];

export const sortableColumns = [
  "Date",
  "Pack",
  "On Floor",
  // "Delivery",
  "Btls Avail",
  "Cs Avail",
  "Price per Case(€)",
  "Price per Bottle(€)",
  "Location",
  "Incoterms",
];

export const widthColoumns = [
  "Supplier Name",
  "On Floor",
  "Delivery",
  "Location",
  "Incoterms",
];

export const coloumns2 = [
  "Date",
  "Category",
  "Supplier Name",
  "S. Code",
  "Description",
  "Pack",
  "Size",
  "ABV",
  "Type",
  "Variant",
  "Coded/ Decoded",
  "Btls Avail",
  "Cs Avail",
  "Price per Case(€)",
  "Price per Case($)",
  "Price per Case(£)",
  "Price per Bottle(€)",
  "Price per Bottle($)",
  "Price per Bottle(£)",
  "Status",
  "Delivery",
  "Location",
  // "On Floor",
  // "Incoterms",
];

export const salesColoumns2 = [
  "Date",
  "Category",
  "S. Code",
  "Description",
  "Pack",
  "Size",
  "ABV",
  "Type",
  "Variant",
  "Coded/ Decoded",
  "Btls Avail",
  "Cs Avail",
  "Price per Case(€)",
  "Price per Case($)",
  "Price per Case(£)",
  "Price per Bottle(€)",
  "Price per Bottle($)",
  "Price per Bottle(£)",
  "Status",
  "Delivery",
  "Location",
  // "On Floor",
  // "Incoterms",
];
