import React, { useEffect, useState } from "react";
import { Table, Row, Col, Card } from "react-bootstrap";
import styles from "../../_css/SupplierDetails.module.css";
import { ChevronDown, ChevronUp, Dot } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import Paginate from "./Pagination";
import {
  formatContact,
  formatDate,
  getAbvValue,
  getDollarValue,
  getEuroValue,
  getFixedNumber,
  getPoundValue,
} from "../helper/formatters";
import { startLoader, stopLoader } from "../../_store/reducers/loaderReducer";
import { notifyError, notifyInfo, notifySuccess } from "../helper/Toaster";
import _ from "lodash";
import { getOfferManagementS1, offerMgmtEdit } from "../../requests";
import { TOKEN_ERRORS } from "../../_data/codes";
import handleSessionClear from "../helper/handleSessionClear";
import {
  coloumns,
  salsesColumns,
  sortableColumns,
  widthColoumns,
} from "../../_data/tableHeaders";
import useViewportHeight from "../hooks/useViewPortHeight";
import { isValidMoneyNumber } from "../helper/validators";

const SupplierDetail = (props) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { percentageHeight } = useViewportHeight(65);

  const [data, setData] = useState([]);
  const [cheapestAlcohol, setCheapestAlcohol] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    totalPages: 1,
    totalPosts: 0,
  });
  const [sortedColumn, setSortedColumn] = useState({
    column: String,
    sortDsc: true,
  });
  const [perPage, setPerPage] = useState(5);
  const [conversionRate, setConversionRate] = useState({
    dollarForEuro: 0,
    dollarForPound: 0,
    euroForDollar: 0,
    euroForPound: 0,
    poundForDollar: 0,
    poundForEuro: 0,
  });
  const [dataSorting, setDataSorting] = useState({
    date: -1,
  });
  const [emailContent, setEmailContent] = useState("");
  const [editEnabled, setEditEnabled] = useState(props?.editEnabled);
  const [editableCell, setEditableCell] = useState({
    rowKey: null,
  });

  const access_token = localStorage.getItem("access_token_MN");
  const isSales =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 3;
  const isAdmin =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 2;

  useEffect(() => {
    setEditEnabled(props?.editEnabled);
  }, [props]);

  useEffect(() => {
    if (!access_token) {
      Navigate("/login");
    } else {
      getOffers();
      setEditEnabled(props?.editEnabled);
    }
  }, [page, perPage, dataSorting]);

  const getNumbericValue = (value) => {
    if (!value) {
      return "-";
    } else if (typeof value === "object") {
      return value.$numberDecimal;
    } else {
      return value;
    }
  };

  const getCheveron = (key) => {
    let lowerKey = _.toLower(key);

    if (lowerKey === "date") lowerKey = "date";
    if (lowerKey === "btls avail") lowerKey = "btlsAvail";
    if (lowerKey === "cs avail") lowerKey = "csAvail";
    if (lowerKey === "price per case(€)") lowerKey = "pricePerCaseEuro";
    if (lowerKey === "price per bottle(€)") lowerKey = "pricePerBottleEuro";

    return (
      <i>
        {Object.keys(dataSorting)[0] === lowerKey ? (
          dataSorting[lowerKey] === -1 ? (
            <ChevronUp size={16} />
          ) : (
            <ChevronDown size={16} />
          )
        ) : (
          <ChevronUp size={16} />
        )}
      </i>
    );
  };

  const handleSort = (key) => {
    let lowerKey = _.toLower(key);

    if (lowerKey === "date") lowerKey = "date";
    if (lowerKey === "btls avail") lowerKey = "btlsAvail";
    if (lowerKey === "cs avail") lowerKey = "csAvail";
    if (lowerKey === "price per case(€)") lowerKey = "pricePerCaseEuro";
    if (lowerKey === "price per bottle(€)") lowerKey = "pricePerBottleEuro";

    setDataSorting((prevValue) => ({
      [lowerKey]: prevValue[lowerKey] === 1 ? -1 : 1,
    }));
    // const lowerKey = _.toLower(key);
    // const previousLowerKey = !_.isEmpty(sortedColumn.column)
    //   ? _.toLower(sortedColumn.column)
    //   : null;
    // let sortedData = data;
    // let coloumnChanged = false;

    // if (previousLowerKey && lowerKey !== previousLowerKey) {
    //   coloumnChanged = true;
    //   sortedData = [...data].sort((a, b) => {
    //     if (a[previousLowerKey] < b[previousLowerKey])
    //       return sortedColumn.sortDsc ? -1 : 1;
    //     if (a[previousLowerKey] > b[previousLowerKey])
    //       return sortedColumn.sortDsc ? 1 : -1;
    //     return 0;
    //   });
    // }

    // const sortingBasis = coloumnChanged ? coloumnChanged : sortedColumn.sortDsc;
    // sortedData = [...sortedData].sort((a, b) => {
    //   if (a[lowerKey] < b[lowerKey]) return sortingBasis ? -1 : 1;
    //   if (a[lowerKey] > b[lowerKey]) return sortingBasis ? 1 : -1;
    //   return 0;
    // });

    // setSortedColumn({ column: key, sortDsc: !sortingBasis });
    // setData(sortedData);
  };

  const handleNext = async (page) => {
    if (page + 1 <= pageInfo.totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = async (page) => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const handleResetPage = () => {
    setPage(1);
  };

  const handlePerPage = (count) => {
    // setPage(1)
    setPerPage(count);
  };

  const getOffers = async () => {
    dispatch(startLoader());

    const sCode = props?.sCode;
    try {
      const payload = {
        page,
        limit: perPage || 5,
        sCode,
        dataSorting,
      };
      const response = await getOfferManagementS1(payload);

      if (response.status === 200) {
        // setAuthFalse();
        const pageInfo = {
          totalPages: response.data.totalPages || 1,
          totalPosts: response.data.totalProducts || 0,
        };

        setCheapestAlcohol(response?.data?.cheapestAlcohol);
        setSupplierInfo(response?.data?.supplierInformation);
        setData(response?.data?.products);
        setPageInfo(pageInfo);
        setConversionRate(response?.data?.conversionRate);
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleShowEmail = (item) => {
    const emailHtml = item?.rawEmail;
    if (!emailHtml) {
      notifyInfo("Email is not available");
      return;
    }
    setEmailContent(emailHtml);
  };

  // EDITABLE COLUMN CODE STARTS HERE
  const handleCellClick = (e, key) => {
    e.preventDefault();
    const column = {
      rowKey: key,
    };
    if (isAdmin) setEditableCell(column);
  };

  const handleCellChange = (e, rowIndex) => {
    const newData = [...data];
    const { name, value } = e.target;
    newData[rowIndex][name] = value;
    setData(newData);
  };

  const handleKeyDown = (event, column, itemId) => {
    if (event.key === "Enter") {
      const value = event.target.value;
      if (column === "pricePerCaseEuro" || column === "pricePerBottleEuro") {
        if (!isValidMoneyNumber(value)) {
          notifyError("Not a Valid Entry!");
          return;
        }
      }
      // Call the function when Enter is pressed
      handleCellUpdate(column, value, itemId);
    } else if (event.key == "Escape") {
      console.log("esc clicked");
    }
  };

  const handleCellUpdate = async (column, value, offerId) => {
    if (_.isEmpty(column) || _.isEmpty(value) || _.isEmpty(offerId)) return;
    dispatch(startLoader());

    try {
      const payload = {
        offerId,
        column,
        value,
      };

      const response = await offerMgmtEdit(payload);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  return (
    <div className="container d-flex flex-column gap-4">
      <Row>
        {/* <Col md={6}> */}
        <Card className={styles.supplierCard}>
          <Card.Body className="p-0">
            <Card.Title className={styles.cardOne}>
              <h4>Supplier Information</h4>
              <span
                className={`${styles["status-supplier"]} ${
                  supplierInfo.length > 0 && supplierInfo[0].onboarded === "Yes"
                    ? styles.onboarded
                    : styles.offboarded
                }`}
              >
                <i className={styles.dot}>
                  <Dot size={24} />
                </i>
                {supplierInfo.length > 0 && supplierInfo[0].onboarded === "Yes"
                  ? "Onboarded"
                  : "offboarded"}
              </span>
            </Card.Title>
            {supplierInfo.length > 0 && (
              <Table className={styles.table} bordered>
                <tbody>
                  <tr>
                    <td>
                      <span className={styles.tableColumns}>Full Name:</span>{" "}
                      {supplierInfo[0].supplierName}
                    </td>
                    <td>
                      <span className={styles.tableColumns}>Company name:</span>{" "}
                      {supplierInfo[0].companyName}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className={styles.tableColumns}>Contact:</span>{" "}
                      {formatContact(supplierInfo[0].contact || 6845550102)}
                    </td>
                    <td>
                      <span className={styles.tableColumns}>
                        Supplier code:
                      </span>{" "}
                      {supplierInfo[0].sCode}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className={styles.tableColumns}>Mail Id:</span>{" "}
                      {supplierInfo[0].email}
                    </td>
                    <td>
                      <span className={styles.tableColumns}>Location:</span>{" "}
                      {supplierInfo[0].location}
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
        {/* </Col> */}
        {/* <Col md={6}>
          <Card className={`${styles.tableContainer} ${styles.cardTwo}`}>
            <Card.Body className="p-0">
              <Card.Title className={styles.cardTwoTitle}>
                Cheapest Alcohols
              </Card.Title>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Size</th>
                    <th>
                      Price <small>(per bottle)</small>
                    </th>
                    <th>Last offer date</th>
                    <th>
                      Availability <small>(cases)</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cheapestAlcohol.map((el, index) => (
                    <tr key={index}>
                      <td>{el.itemName}</td>
                      <td>{el.size}L</td>
                      <td>€{el.pricePerBottleEuro}</td>
                      <td>{formatDate(el.date)}</td>
                      <td>{el.availability}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>Absolut Citron</td>
                    <td>1L</td>
                    <td>€21</td>
                    <td>05/05/2024</td>
                    <td>400</td>
                  </tr>
                  <tr>
                    <td>Brandi</td>
                    <td>75CL</td>
                    <td>€20</td>
                    <td>09/06/2024</td>
                    <td>300</td>
                  </tr> 
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
      <Row>
        <div className={`p-0 ${styles["table-wrapper"]}`}>
          <div className="position-relative">
            <div
              style={{ maxHeight: percentageHeight }}
              className={`${styles.tableOffer} overflow-auto table-height-400`}
            >
              <table className="table">
                <thead>
                  <tr>
                    {(isSales ? salsesColumns : coloumns).map((e, index) => (
                      <th
                        className={`pe-cursor text-center ${
                          e === "Enquiry Status" && "enquiry-status"
                        }`}
                        onClick={() => handleSort(e)}
                        key={index}
                        style={{
                          minWidth: widthColoumns.includes(e)
                            ? "98px"
                            : e === "Enquiry Status" || e === ""
                            ? "100px"
                            : "80px",
                          height: e === "Enquiry Status" && "3.27rem",
                        }}
                      >
                        <div className={`${styles["col-value"]}`}>
                          <span>{e}</span>
                          {sortableColumns.includes(e) && getCheveron(e)}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td className="sticky-left">
                        <div className={styles.statusContainer}>
                          {item.notes.length > 0 ? (
                            <span
                              className={`${styles.status} ${
                                item.notes[item.notes.length - 1].type ===
                                "Purchase"
                                  ? styles["actions-tags-purchased"]
                                  : item.notes[item.notes.length - 1].type ===
                                    "Reject"
                                  ? styles.statusRejected
                                  : item.notes[item.notes.length - 1].type ===
                                    "Request"
                                  ? styles.statusRequest
                                  : styles.statusPending
                              }`}
                            >
                              {item.notes[item.notes.length - 1].type ===
                              "Purchase"
                                ? "Purchased"
                                : item.notes[item.notes.length - 1].type ===
                                  "Reject"
                                ? "Rejected"
                                : item.notes[item.notes.length - 1].type ===
                                  "Request"
                                ? "Requested"
                                : "Pending"}
                            </span>
                          ) : (
                            <span
                              className={`${styles.status} ${styles.statusNoQuery}`}
                            >
                              No Query
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {formatDate(item.date)}
                        </span>
                      </td>
                      {/* <td>
                      <span className={`${styles["col-value"]}`}>
                        {item.mfCode}
                      </span>
                    </td> */}
                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_0`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_0` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.category}
                              onChange={(e) => handleCellChange(e, index, 0)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "category", item._id)
                              }
                              name="category"
                              autoFocus
                            />
                          ) : (
                            <span>{item.category}</span>
                          )}
                        </span>
                      </td>
                      {!isSales && (
                        <td>
                          <span className={`${styles["col-value"]}`}>
                            {/* {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_1` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.supplierName}
                            onChange={(e) => handleCellChange(e, index, 1)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "supplierName", item._id)
                            }
                            name="supplierName"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_1`)
                            }
                          >
                            {item.supplierName}
                          </span>
                        )} */}
                            <span>{item.supplierName}</span>
                          </span>
                        </td>
                      )}
                      <td
                      // onClick={(e) => handleCellClick(e, `row_${index}_col_1`)}
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_1` ? (
                            <input
                              type="number"
                              className="edit-column"
                              value={item.sCode}
                              onChange={(e) => handleCellChange(e, index, 1)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "sCode", item._id)
                              }
                              name="sCode"
                              autoFocus
                            />
                          ) : (
                            <span>{item.sCode}</span>
                          )}
                        </span>
                      </td>

                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_2`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_2` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.itemName}
                              onChange={(e) => handleCellChange(e, index, 2)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "itemName", item._id)
                              }
                              name="itemName"
                              autoFocus
                            />
                          ) : (
                            <span>{item.itemName}</span>
                          )}

                          {/* <span>{item.itemName}</span> */}
                        </span>
                      </td>
                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_3`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_3` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.pack}
                              onChange={(e) => handleCellChange(e, index, 3)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "pack", item._id)
                              }
                              name="pack"
                              autoFocus
                            />
                          ) : (
                            <span>{getNumbericValue(item.pack)}</span>
                          )}
                        </span>
                      </td>
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {/* {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_4` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.size}
                            onChange={(e) => handleCellChange(e, index, 4)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "size", item._id)
                            }
                            name="size"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_4`)
                            }
                          >
                            {item.size}
                          </span>
                        )} */}
                          <span>{item.size}</span>
                        </span>
                      </td>
                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_4_1`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_4_1` ? (
                            <input
                              type="number"
                              className="edit-column"
                              value={item?.abv}
                              onChange={(e) => handleCellChange(e, index, 4)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "abv", item._id)
                              }
                              name="abv"
                              autoFocus
                            />
                          ) : (
                            <span>{getAbvValue(item?.abv)}</span>
                          )}
                        </span>
                      </td>
                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_5`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_5` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.giftBoxOrNoGiftBox}
                              onChange={(e) => handleCellChange(e, index, 5)}
                              onKeyDown={(event) =>
                                handleKeyDown(
                                  event,
                                  "giftBoxOrNoGiftBox",
                                  item._id
                                )
                              }
                              name="giftBoxOrNoGiftBox"
                              autoFocus
                            />
                          ) : (
                            <span>{item.giftBoxOrNoGiftBox}</span>
                          )}
                        </span>
                      </td>
                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_6`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_6` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.refNref}
                              onChange={(e) => handleCellChange(e, index, 6)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "refNref", item._id)
                              }
                              name="refNref"
                              autoFocus
                            />
                          ) : (
                            <span>{item.refNref}</span>
                          )}
                        </span>
                      </td>
                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_7`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_7` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.codedOrDecoded}
                              onChange={(e) => handleCellChange(e, index, 7)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "codedOrDecoded", item._id)
                              }
                              name="codedOrDecoded"
                              autoFocus
                            />
                          ) : (
                            <span>{item.codedOrDecoded}</span>
                          )}
                        </span>
                      </td>

                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_8`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_8` ? (
                            <input
                              type="number"
                              className="edit-column"
                              value={getNumbericValue(item.btlsAvail)}
                              onChange={(e) => handleCellChange(e, index, 8)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "btlsAvail", item._id)
                              }
                              name="btlsAvail"
                              autoFocus
                            />
                          ) : (
                            <span
                              onClick={(e) =>
                                handleCellClick(e, `row_${index}_col_8`)
                              }
                            >
                              {getNumbericValue(item.btlsAvail)}
                            </span>
                          )}
                          {/* <span>{getNumbericValue(item.btlsAvail)}</span> */}
                        </span>
                      </td>
                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_9`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_9` ? (
                            <input
                              type="number"
                              className="edit-column"
                              value={getNumbericValue(item.csAvail)}
                              onChange={(e) => handleCellChange(e, index, 9)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "csAvail", item._id)
                              }
                              name="csAvail"
                              autoFocus
                            />
                          ) : (
                            <span
                              onClick={(e) =>
                                handleCellClick(e, `row_${index}_col_9`)
                              }
                            >
                              {getNumbericValue(item.csAvail)}
                            </span>
                          )}
                          {/* <span>{getNumbericValue(item.csAvail)}</span> */}
                        </span>
                      </td>

                      <td
                        onClick={(e) => {
                          if (item.currency === "EUR") {
                            handleCellClick(e, `row_${index}_col_10`);
                          }
                        }}
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_10` ? (
                            <input
                              type="text"
                              className="edit-column"
                              placeholder={getEuroValue(
                                getNumbericValue(item.pricePerCaseEuro),
                                item.currency,
                                conversionRate
                              )}
                              onChange={(e) => handleCellChange(e, index, 10)}
                              onKeyDown={(event) =>
                                handleKeyDown(
                                  event,
                                  "pricePerCaseEuro",
                                  item._id
                                )
                              }
                              name="pricePerCaseEuro"
                              autoFocus
                            />
                          ) : (
                            <span>
                              {getEuroValue(
                                getNumbericValue(item.pricePerCaseEuro),
                                item.currency,
                                conversionRate
                              )}{" "}
                              {item.currency === "EUR" && "*"}
                            </span>
                          )}
                          {/* <span
                          onClick={(e) =>
                            handleCellClick(e, `row_${index}_col_10`)
                          }
                        >
                          {getEuroValue(
                            getNumbericValue(item.pricePerCaseEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span> */}
                        </span>
                      </td>
                      <td
                        onClick={(e) => {
                          if (item.currency === "USD") {
                            handleCellClick(e, `row_${index}_col_11`);
                          }
                        }}
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_11` ? (
                            <input
                              type="text"
                              className="edit-column"
                              placeholder={getDollarValue(
                                getNumbericValue(item.pricePerCaseEuro),
                                item.currency,
                                conversionRate
                              )}
                              onChange={(e) => handleCellChange(e, index, 11)}
                              onKeyDown={(event) =>
                                handleKeyDown(
                                  event,
                                  "pricePerCaseEuro",
                                  item._id
                                )
                              }
                              name="pricePerCaseEuro"
                              autoFocus
                            />
                          ) : (
                            <span
                            // onClick={(e) =>
                            //   handleCellClick(e, `row_${index}_col_11`)
                            // }
                            >
                              {getDollarValue(
                                getNumbericValue(item.pricePerCaseEuro),
                                item.currency,
                                conversionRate
                              )}{" "}
                              {item.currency === "USD" && "*"}
                            </span>
                          )}
                          {/* <span
                          onClick={(e) =>
                            handleCellClick(e, `row_${index}_col_11`)
                          }
                        >
                          {getDollarValue(
                            getNumbericValue(item.pricePerCaseEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span> */}
                        </span>
                      </td>
                      <td
                        onClick={(e) => {
                          if (item.currency === "GBP") {
                            handleCellClick(e, `row_${index}_col_11_1`);
                          }
                        }}
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_11_1` ? (
                            <input
                              type="text"
                              className="edit-column"
                              placeholder={getPoundValue(
                                getNumbericValue(item.pricePerCaseEuro),
                                item.currency,
                                conversionRate
                              )}
                              onChange={(e) => handleCellChange(e, index, 111)}
                              onKeyDown={(event) =>
                                handleKeyDown(
                                  event,
                                  "pricePerCaseEuro",
                                  item._id
                                )
                              }
                              name="pricePerCaseEuro"
                              autoFocus
                            />
                          ) : (
                            <span>
                              {getPoundValue(
                                getNumbericValue(item.pricePerCaseEuro),
                                item.currency,
                                conversionRate
                              )}{" "}
                              {item.currency === "GBP" && "*"}
                            </span>
                          )}
                        </span>
                      </td>
                      <td
                        onClick={(e) => {
                          if (item.currency === "EUR") {
                            handleCellClick(e, `row_${index}_col_12`);
                          }
                        }}
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_12` ? (
                            <input
                              type="text"
                              className="edit-column"
                              placeholder={getEuroValue(
                                getNumbericValue(item.pricePerBottleEuro),
                                item.currency,
                                conversionRate
                              )}
                              onChange={(e) => handleCellChange(e, index, 12)}
                              onKeyDown={(event) =>
                                handleKeyDown(
                                  event,
                                  "pricePerBottleEuro",
                                  item._id
                                )
                              }
                              name="pricePerBottleEuro"
                              autoFocus
                            />
                          ) : (
                            <span
                            // onClick={(e) =>
                            //   handleCellClick(e, `row_${index}_col_12`)
                            // }
                            >
                              {getEuroValue(
                                getNumbericValue(item.pricePerBottleEuro),
                                item.currency,
                                conversionRate
                              )}{" "}
                              {item.currency === "EUR" && "*"}
                            </span>
                          )}
                        </span>
                      </td>
                      <td
                        onClick={(e) => {
                          if (item.currency === "USD") {
                            handleCellClick(e, `row_${index}_col_13`);
                          }
                        }}
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_13` ? (
                            <input
                              type="text"
                              className="edit-column"
                              placeholder={getDollarValue(
                                getNumbericValue(item.pricePerBottleEuro),
                                item.currency,
                                conversionRate
                              )}
                              onChange={(e) => handleCellChange(e, index, 13)}
                              onKeyDown={(event) =>
                                handleKeyDown(
                                  event,
                                  "pricePerBottleEuro",
                                  item._id
                                )
                              }
                              name="pricePerBottleEuro"
                              autoFocus
                            />
                          ) : (
                            <span
                            // onClick={(e) =>
                            //   handleCellClick(e, `row_${index}_col_13`)
                            // }
                            >
                              {getDollarValue(
                                getNumbericValue(item.pricePerBottleEuro),
                                item.currency,
                                conversionRate
                              )}{" "}
                              {item.currency === "USD" && "*"}
                            </span>
                          )}
                        </span>
                      </td>

                      <td
                        onClick={(e) => {
                          if (item.currency === "GBP") {
                            handleCellClick(e, `row_${index}_col_13_1`);
                          }
                        }}
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_13_1` ? (
                            <input
                              type="text"
                              className="edit-column"
                              placeholder={getPoundValue(
                                getNumbericValue(item.pricePerBottleEuro),
                                item.currency,
                                conversionRate
                              )}
                              onChange={(e) => handleCellChange(e, index, 131)}
                              onKeyDown={(event) =>
                                handleKeyDown(
                                  event,
                                  "pricePerBottleEuro",
                                  item._id
                                )
                              }
                              name="pricePerBottleEuro"
                              autoFocus
                            />
                          ) : (
                            <span
                            // onClick={(e) =>
                            //   handleCellClick(e, `row_${index}_col_13`)
                            // }
                            >
                              {getPoundValue(
                                getNumbericValue(item.pricePerBottleEuro),
                                item.currency,
                                conversionRate
                              )}{" "}
                              {item.currency === "GBP" && "*"}
                            </span>
                          )}
                        </span>
                      </td>
                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_14`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_14` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.status}
                              onChange={(e) => handleCellChange(e, index, 14)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "status", item._id)
                              }
                              name="status"
                              autoFocus
                            />
                          ) : (
                            <span>{item.status}</span>
                          )}
                        </span>
                      </td>

                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_15`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_15` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.leadTime || "-"}
                              onChange={(e) => handleCellChange(e, index, 15)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "leadTime", item._id)
                              }
                              name="leadTime"
                              autoFocus
                            />
                          ) : (
                            <span>{item.leadTime || "-"}</span>
                          )}
                        </span>
                      </td>

                      <td
                        onClick={(e) =>
                          handleCellClick(e, `row_${index}_col_16`)
                        }
                      >
                        <span className={`${styles["col-value"]}`}>
                          {editEnabled &&
                          editableCell.rowKey === `row_${index}_col_16` ? (
                            <input
                              type="text"
                              className="edit-column"
                              value={item.location}
                              onChange={(e) => handleCellChange(e, index, 16)}
                              onKeyDown={(event) =>
                                handleKeyDown(event, "location", item._id)
                              }
                              name="location"
                              autoFocus
                            />
                          ) : (
                            <span>{item.location}</span>
                          )}
                        </span>
                      </td>
                      {isAdmin && (
                        <td>
                          <div
                            onClick={() => handleShowEmail(item)}
                            className={styles.statusContainer}
                          >
                            <span
                              key={index}
                              className={`fw-500 pe-cursor ${styles.status} ${styles["view-offers"]}`}
                            >
                              View Email
                            </span>
                          </div>
                        </td>
                      )}
                      {/* <td>
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_17` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.onFloor}
                            onChange={(e) => handleCellChange(e, index, 17)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "onFloor", item._id)
                            }
                            name="onFloor"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_17`)
                            }
                          >
                            {item.onFloor}
                          </span>
                        )}
                      </span>
                    </td> */}
                      {/* <td>
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_18` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.incoterms}
                            onChange={(e) => handleCellChange(e, index, 18)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "incoterms", item._id)
                            }
                            name="incoterms"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_18`)
                            }
                          >
                            {item.incoterms}
                          </span>
                        )}
                      </span>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{ bottom: "-70px" }}
              className="position-absolute w-100"
            >
              <Paginate
                currentPage={page}
                pageInfo={pageInfo}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                handlePerPage={handlePerPage}
                resetPage={handleResetPage}
              />
            </div>
          </div>
          {emailContent && (
            <div className={styles["email-viewer"]}>
              <button
                className="btn btn-sm btn-warning"
                onClick={() => setEmailContent("")}
              >
                Close
              </button>
              <iframe width="100%" height="100%" srcDoc={emailContent}></iframe>
            </div>
          )}
        </div>
      </Row>
    </div>
  );
};

export default SupplierDetail;
