export const checkNewNotif = (date) => {
    const notifDate = new Date(date);

      // Get today's date and time in milliseconds
  const now = Date.now();

  // Convert the given date to milliseconds (if it's not already in milliseconds)
  const dateInMilliseconds = notifDate instanceof Date ? notifDate.getTime() : new Date(notifDate).getTime();

  // Calculate the difference in milliseconds
  const difference = now - dateInMilliseconds;

  // Check if the difference is less than 24 hours (in milliseconds)
  return difference < (24 * 60 * 60 * 1000);
}

export function isValidMoneyNumber(value) {
  // Check if the value is an integer or a float with at most two decimal places
  const regex = /^\d+(\.\d{0,2})?$/;
  return regex.test(value.toString());
}