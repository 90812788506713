import React, { useEffect, useState } from "react";
import styles from "../_css/ProductCatalogue.module.css";
import { ChevronDown, ChevronUp, Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Paginate from "./UI/Pagination";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { notifyError, notifyInfo, notifySuccess } from "./helper/Toaster";
import { useDispatch, useSelector } from "react-redux";
import Notifications from "./common/Notifications";
import _ from "lodash";
import Toggle from "react-toggle";
import SelectComponent from "./common/SelectComponent";
import {
  getProductCatalogs,
  getProductCatalogsEdit,
  getProductCatalogsSearch,
} from "../requests";
import { TOKEN_ERRORS } from "../_data/codes";
import handleSessionClear from "./helper/handleSessionClear";
import SearchInput from "./common/SearchInput"; // Import the SearchInput component
import { preserveProductCatalog } from "../_store/reducers/preserveStateReducer";
import useDebounce from "./hooks/useDebounce";
import useViewportHeight from "./hooks/useViewPortHeight";

const sortableColumns = ["Name", "Category", "Cases", "Brand", "Size", "Pack"];

const ProductCatalogue = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const previousState = useSelector((state) => state.preserveState);
  const {percentageHeight, viewportHeight} = useViewportHeight(65);
  const [searchLike, setSearchLike] = useState("");
  const [data, setData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    totalPages: 1,
    totalPosts: 0,
  });
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [editableCell, setEditableCell] = useState({
    rowKey: null,
  });
  const [editEnabled, setEditEnabled] = useState(false);
  const [sortingData, setSortingData] = useState(null);
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [alcoholInfo, setAlcoholInfo] = useState({
    name: "",
    size: 0,
  });

  const [checkPreviousState, setCheckPreviousState] = useState("loading");

  const [updateColumn, setUpdateColumn] = useState({
    itemName: "",
    size: "",
  });
  const [dataSorting, setDataSorting] = useState({
    createdAt: -1,
  });
  const debouncedSearch = useDebounce(() => searchLike, 500);

  const access_token = localStorage.getItem("access_token_MN");
  const isAdmin =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 2;

  useEffect(() => {
    setComponentState();
  }, []);

  useEffect(() => {
    if (access_token && !checkPreviousState !== "loading") {
      getProducts();
    }
  }, [
    page,
    perPage,
    dataSorting,
    checkPreviousState,
    sortingData,
    access_token,
    debouncedSearch,
  ]);

  // USE EFFECTS
  // useEffect(() => {
  //   if (access_token && !checkPreviousState !== "loading") {
  //     getProducts();
  //   }
  // }, [sortingData, access_token, Navigate, checkPreviousState]);

  useEffect(() => {
    dispatch(
      preserveProductCatalog({
        preserveState: {
          sortingData,
          perPage,
          alcoholInfo,
          updateColumn,
          dataSorting,
          page,
          searchLike,
          data,
          orgData,
          pageInfo,
          search,
          options,
          options2,
        },
        location: "ProductCatalogue",
      })
    );
  }, [
    sortingData,
    perPage,
    alcoholInfo,
    updateColumn,
    dataSorting,
    page,
    searchLike,
    data,
    orgData,
    pageInfo,
    search,
    options,
    options2,
  ]);

  const setComponentState = () => {
    // Set component state based on Redux state
    let prevState = false;
    if (
      previousState &&
      previousState.productCatalog
    ) {
      const productCatalog = previousState.productCatalog;
      prevState = true;
      setPage(productCatalog.page || 1);
      setPerPage(productCatalog.perPage || 5);
      setSortingData(productCatalog.sortingData || null);
      setSearchLike(productCatalog.searchLike || "");
      setData(productCatalog.data || []);
      setOrgData(productCatalog.orgData || []);
      setPageInfo(
        productCatalog.pageInfo || {
          totalPages: 1,
          totalPosts: 0,
        }
      );
      setAlcoholInfo(
        productCatalog.alcoholInfo || {
          name: "",
          size: 0,
        }
      );
      setUpdateColumn(
        productCatalog.updateColumn || {
          itemName: "",
          size: "",
        }
      );
      setDataSorting(
        productCatalog.dataSorting || {
          createdAt: -1,
        }
      );
      setSearchLike(productCatalog.searchLike || "");
      setOptions(productCatalog.options || []);
      setOptions2(productCatalog.options2 || []);
    }
    if (prevState) {
      setCheckPreviousState("loaded");
    } else {
      setCheckPreviousState("not-loaded");
    }
  };

  const getProducts = async () => {
    if (checkPreviousState === "loading") return;

    dispatch(startLoader());

    // CHECK IF SORTING APPLIED
    let isSorting = false;
    if (sortingData) {
      isSorting = true;
    }
    try {
      const payload = {
        page,
        limit: perPage || 5,
        isSorting,
        sortingData,
        dataSorting,
        searchLike,
      };

      const response = await getProductCatalogs(payload);
      if (response.status !== 200) {
        // setAuthFalse();
      }

      if (!sortingData?.column && response?.data?.alcoholInfo?.name) {
        setSortingData((prevValue) => {
          return {
            ...prevValue,
            column: "name",
            value: [response?.data?.alcoholInfo?.name],
          };
        });
      }

      if (!sortingData?.column2 && response?.data?.alcoholInfo?.size) {
        setSortingData((prevValue) => {
          return {
            ...prevValue,
            column2: "size",
            value2: [response?.data?.alcoholInfo?.size],
          };
        });
      }
      const pageInfo = {
        totalPages: response.data.totalPages || 1,
        totalPosts: response.data.totalProducts || 0,
      };

      setData(response?.data?.products);
      const orgData = _.cloneDeep(response?.data?.products);
      setOrgData(orgData);
      setPageInfo(pageInfo);
      setAlcoholInfo(response?.data?.alcoholInfo);
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleResetPage = () => {
    setPage(1);
  };

  const onFocusHandler = async (column) => {
    if (column === "size") {
      if (!sortingData?.value || _.isEmpty(sortingData?.value)) {
        notifyInfo("Please select Description First!");
        return;
      }
      await getSuggestionData("size", alcoholInfo?.name, true);
    } else if (column === "itemName") {
      await getSuggestionData(column);
    }
  };

  // GET SUGGESTION DATA FOR FILTER
  const getSuggestionData = async (
    inputColumn,
    inputValue,
    sizeData = false
  ) => {
    if (_.isEmpty(inputColumn) && _.isEmpty(inputValue)) return;

    setLoadingSuggestion(true);
    try {
      const payload = {
        searchFor: inputColumn,
        searchString: inputValue,
        searchItem: alcoholInfo?.name,
        sizeData,
      };

      const response = await getProductCatalogsSearch(payload);
      if (response.status === 200) {
        const { formattedData } = response.data;

        if (inputColumn === "itemName") {
          setOptions(formattedData);
        }

        if (sizeData) {
          setOptions2(formattedData);
        }
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      setLoadingSuggestion(false);
    }
  };

  const handleSort = (key) => {
    // let lowerKey = _.toLower(key);
    // if (lowerKey === "cases") lowerKey = "csAvail";

    setDataSorting((prevValue) => ({
      [key]: prevValue[key] === 1 ? -1 : 1,
    }));
    // let previousLowerKey = !_.isEmpty(sortedColumn.column)
    //   ? _.toLower(sortedColumn.column)
    //   : null;
    // if (previousLowerKey === "cases") previousLowerKey = "availability";
    // let sortedData = data;
    // let coloumnChanged = false;

    // if (previousLowerKey && lowerKey !== previousLowerKey) {
    //   coloumnChanged = true;
    //   sortedData = [...data].sort((a, b) => {
    //     if (a[previousLowerKey] < b[previousLowerKey])
    //       return sortedColumn.sortDsc ? -1 : 1;
    //     if (a[previousLowerKey] > b[previousLowerKey])
    //       return sortedColumn.sortDsc ? 1 : -1;
    //     return 0;
    //   });
    // }

    // const sortingBasis = coloumnChanged ? coloumnChanged : sortedColumn.sortDsc;
    // sortedData = [...sortedData].sort((a, b) => {
    //   if (a[lowerKey] < b[lowerKey]) return sortingBasis ? -1 : 1;
    //   if (a[lowerKey] > b[lowerKey]) return sortingBasis ? 1 : -1;
    //   return 0;
    // });

    // setSortedColumn({ column: key, sortDsc: !sortingBasis });
    // setData(sortedData);
  };

  const handleOfferManagement = (item) => {
    if (_.isEmpty(item.itemName)) {
      notifyInfo("Description is not Defined");
      return;
    }
    Navigate("/offer-management", {
      state: {
        itemName: item.itemName,
        size: item.size,
        lastPage: "Product Offer",
      },
    });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchLike(value);
  };

  const handleSearchLikeSubmit = async (event) => {
    if (event.key === "Enter") {
      // if (searchLike) {
      // if any filter is applied then don't fetch products again
      let fetchProducts = true;
      if (sortingData) {
        setSortingData(null);
        fetchProducts = false;
      }
      if (page > 1) {
        handleResetPage();
        fetchProducts = false;
      }
      if (alcoholInfo?.name) {
        setAlcoholInfo({
          name: "",
          size: 0,
        });
        fetchProducts = false;
      }
      if (fetchProducts) {
        await getProducts();
      }
      // }
    }
  };

  const handleNext = async (page) => {
    if (page + 1 <= pageInfo.totalPages) {
      setPage(page + 1);
      // setData(initialData.slice(5, 10));
    }
  };

  const handlePrevious = async (page) => {
    if (page - 1 > 0) {
      setPage(page - 1);
      // setData(initialData.slice(0, 5));
    }
  };

  const handlePerPage = (count) => {
    setPerPage(count);
  };

  const filteredData = data.filter(
    (item) =>
      item.itemName.toLowerCase().includes(search.toLowerCase()) ||
      item.category.toLowerCase().includes(search.toLowerCase())
  );

  const handleValueChange = async (value, column) => {
    // if (value.length > 0 && column) {
    //   let values = [];
    //   for (const data of value) {
    //     values.push(data.value);
    //   }
    //   const reqData = {
    //     value: values,
    //     column: column,
    //   };
    //   setSortingData(reqData);
    //   // await getProducts(true, reqData);
    // } else {
    //   setSortingData(null);
    // }
    if (value && column) {
      let values = [];
      values.push(value.value);

      let reqData = {};

      if (column === "itemName") {
        reqData = {
          ...sortingData,
          value: values,
          column: column,
        };
        setAlcoholInfo((prevState) => {
          return {
            ...prevState,
            name: value.value,
          };
        });
      } else {
        reqData = {
          ...sortingData,
          value2: values,
          column2: column,
        };
        setAlcoholInfo((prevState) => {
          return {
            ...prevState,
            size: value.value,
          };
        });
      }
      handleResetPage();
      setSearchLike("");
      setSortingData(reqData);
      // await getOffers(true, reqData);
    }
  };

  const clearValueHandler = () => {
    handleResetPage();
    setOptions([]);
    setSortingData(null);
  };

  const handleCellClick = (e, key) => {
    e.preventDefault();
    const column = {
      rowKey: key,
    };
    if (isAdmin) setEditableCell(column);
  };

  const handleChange = (e, rowIndex) => {
    const newData = [...data];
    const { name, value } = e.target;
    setUpdateColumn({
      itemName: orgData[rowIndex]["itemName"],
      size: orgData[rowIndex]["size"],
    });
    newData[rowIndex][name] = value;
    setData(newData);
  };

  const handleKeyDown = (event, column, itemId) => {
    if (event.key === "Enter") {
      const value = event.target.value;
      // Call the function when Enter is pressed
      handleValueUpdate(column, value, itemId);
    } else if (event.key == "Escape") {
      console.log("esc clicked");
    }
  };

  const handleValueUpdate = async (column, value, itemId) => {
    if (_.isEmpty(column) || _.isEmpty(value) || _.isEmpty(itemId)) return;
    dispatch(startLoader());

    try {
      const payload = {
        ...updateColumn,
        column,
        value,
      };

      const response = await getProductCatalogsEdit(payload);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        await getProducts();
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const setEdit = (event) => {
    if (event.target.checked) {
      setEditEnabled(true);
    } else {
      setEditEnabled(false);
    }
  };

  const getNumbericValue = (value) => {
    if (!value) {
      return "-";
    } else if (typeof value === "object") {
      return value.$numberDecimal;
    } else {
      return value;
    }
  };

  const handleResetFilters = () => {
    setSortingData(null);
    handleResetPage();
    setAlcoholInfo({
      name: "",
      size: 0,
    });
    setSearchLike("");
    // getReports()
  };

  return (
    <div className={`container ml-5rem mt-4 ${styles.productCatalogue}`}>
      <div className={`position-relative mb-4 ${styles.filterSection}`}>
        <div className={styles.container}>
          <div className={`${styles.header} d-flex flex-row`}>
            <div className="col-3">
              <h1 className={styles.title}>Product Catalogue</h1>
            </div>
            <div className={`${styles.filters} col-9`}>
              {/* <select className={styles.select}>
                <option>Name</option>
              </select> */}
              {isAdmin && (
                <div className="d-flex fs-13 gap-1">
                  <label className="react-toggle-label" htmlFor="edit">
                    Edit Table
                  </label>
                  <Toggle
                    id="edit"
                    defaultChecked={editEnabled}
                    onChange={(event) => setEdit(event)}
                    icons={false}
                  />
                </div>
              )}
              <SearchInput
                value={searchLike}
                onChange={handleSearchChange}
                onEnterClick={handleSearchLikeSubmit}
                placeholder="Search"
              />

              <SelectComponent
                options={options}
                value={alcoholInfo?.name}
                placeholder={"Description"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={onFocusHandler}
                inputColumn={"itemName"}
                isMulti={false}
                isClearable={false}
                loadingSuggestion={loadingSuggestion}
              />
              <SelectComponent
                options={options2}
                value={alcoholInfo?.size}
                placeholder={"Size"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={onFocusHandler}
                inputColumn={"size"}
                isMulti={false}
                isClearable={false}
                loadingSuggestion={loadingSuggestion}
                isDisabled={alcoholInfo.name ? false : true}
              />
              <div
                style={{
                  position: "relative",
                }}
                onClick={handleResetFilters}
                className="statusContainer"
              >
                <span
                  className={`fw-500 pe-cursor price-button status view-offers`}
                >
                  Reset Filters
                </span>
              </div>
              {/* <select className={styles.select}>
                <option>Location</option>
              </select> */}

              {/* <select className={styles.select}>
                <option>Date</option>
              </select> */}
              <Notifications />
            </div>
          </div>
        </div>
      </div>
      <div className={`rounded position-relative p-1 ${styles.table}`}>
        <div className="table-height-400" style={{ maxHeight: percentageHeight }}>
          <table className={`table`}>
            <thead>
              <tr>
                {/* <th
                className="pe-cursor"
                // style={{
                //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                // }}
                onClick={() => handleSort("Name")}
              >
                <div
                  style={{ width: "30%" }}
                  className={`${styles["col-value"]}`}
                >
                  <span>Name</span>
                  {sortableColumns.includes("Name") && (
                    <i>
                      {sortedColumn.column === "Name" ? (
                        sortedColumn.sortDsc ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )
                      ) : (
                        <ChevronUp size={16} />
                      )}
                    </i>
                  )}
                </div>
              </th> */}
                <th
                  className="pe-cursor"
                  // style={{
                  //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                  // }}
                  style={{ width: "20%" }}
                  onClick={() => handleSort("category")}
                >
                  <div className={`${styles["col-value"]}`}>
                    <span>Category</span>
                    {sortableColumns.includes("Category") && (
                      <i>
                        {Object.keys(dataSorting)[0] === "category" ? (
                          dataSorting.category === -1 ? (
                            <ChevronUp size={16} />
                          ) : (
                            <ChevronDown size={16} />
                          )
                        ) : (
                          <ChevronUp size={16} />
                        )}
                      </i>
                    )}
                  </div>
                </th>
                <th
                  className="pe-cursor"
                  // style={{
                  //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                  // }}
                  style={{ width: "40%" }}
                  onClick={() => handleSort("itemName")}
                >
                  <div className={`${styles["col-value"]}`}>
                    <span>Description</span>
                    {sortableColumns.includes("Brand") && (
                      <i>
                        {Object.keys(dataSorting)[0] === "itemName" ? (
                          dataSorting.itemName === -1 ? (
                            <ChevronUp size={16} />
                          ) : (
                            <ChevronDown size={16} />
                          )
                        ) : (
                          <ChevronUp size={16} />
                        )}
                      </i>
                    )}
                  </div>
                </th>
                <th
                  className="pe-cursor"
                  // style={{
                  //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                  // }}
                  style={{ width: "10%" }}
                  // onClick={() => handleSort("size")}
                >
                  <div className={`${styles["col-value"]}`}>
                    <span>Size</span>
                    {/* {sortableColumns.includes("Size") && (
                    <i>
                      {sortedColumn.column === "Size" ? (
                        sortedColumn.sortDsc ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )
                      ) : (
                        <ChevronUp size={16} />
                      )}
                    </i>
                  )} */}
                  </div>
                </th>
                {/* <th
                className="pe-cursor"
                // style={{
                //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                // }}
                style={{ width: "10%" }}
                onClick={() => handleSort("pack")}
              >
                <div className={`${styles["col-value"]}`}>
                  <span>Pack</span>
                  {sortableColumns.includes("Pack") && (
                    <i>
                      {Object.keys(dataSorting)[0] === "pack" ? (
                        dataSorting.pack === -1 ? (
                          <ChevronUp size={16} />
                        ) : (
                          <ChevronDown size={16} />
                        )
                      ) : (
                        <ChevronUp size={16} />
                      )}
                    </i>
                  )}
                </div>
              </th> */}
                {/* <th
                className="pe-cursor"
                // style={{
                //   minWidth: widthColoumns.includes(e) ? "98px" : "80px",
                // }}
                style={{ width: "10%" }}
                onClick={() => handleSort("csAvail")}
              >
                <span>Cases</span>
                {sortableColumns.includes("Cases") && (
                  <i>
                    {Object.keys(dataSorting)[0] === "csAvail" ? (
                      dataSorting.csAvail === -1 ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )
                    ) : (
                      <ChevronUp size={16} />
                    )}
                  </i>
                )}
              </th> */}
                <th style={{ width: "10%" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr
                  // className="pe-cursor"
                  onDoubleClickCapture={() => handleOfferManagement(item)}
                  key={index}
                >
                  {/* <td>
                  {editEnabled &&
                  editableCell.rowKey === `row_${index}_col_0` ? (
                    <input
                      type="text"
                      className="edit-column"
                      value={item.name}
                      onChange={(e) => handleChange(e, index, 0)}
                      name="name"
                      onKeyDown={(event) =>
                        handleKeyDown(event, "name", item._id)
                      }
                      // onChange={(e) => handleChange(e, rowIndex, columnKey)}
                      // onBlur={() => setEditing(false)}
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={(e) => handleCellClick(e, `row_${index}_col_0`)}
                    >
                      {item.name}
                    </span>
                  )}
                </td> */}
                  <td onClick={(e) => handleCellClick(e, `row_${index}_col_1`)}>
                    {editEnabled &&
                    editableCell.rowKey === `row_${index}_col_1` ? (
                      <input
                        type="text"
                        className="edit-column"
                        value={item.category}
                        onChange={(e) => handleChange(e, index, 1)}
                        onKeyDown={(event) =>
                          handleKeyDown(event, "category", item._id)
                        }
                        // onChange={(e) => handleChange(e, rowIndex, columnKey)}
                        // onBlur={() => setEditing(false)}
                        name="category"
                        autoFocus
                      />
                    ) : (
                      <span>{item.category}</span>
                    )}
                  </td>
                  <td onClick={(e) => handleCellClick(e, `row_${index}_col_2`)}>
                    {editEnabled &&
                    editableCell.rowKey === `row_${index}_col_2` ? (
                      <input
                        type="text"
                        className="edit-column"
                        value={item.itemName}
                        onChange={(e) => handleChange(e, index, 2)}
                        onKeyDown={(event) =>
                          handleKeyDown(event, "itemName", item._id)
                        }
                        name="itemName"
                        // onChange={(e) => handleChange(e, rowIndex, columnKey)}
                        // onBlur={() => setEditing(false)}
                        autoFocus
                      />
                    ) : (
                      <span>{item.itemName}</span>
                    )}
                  </td>
                  <td onClick={(e) => handleCellClick(e, `row_${index}_col_3`)}>
                    {editEnabled &&
                    editableCell.rowKey === `row_${index}_col_3` ? (
                      <input
                        type="text"
                        className="edit-column"
                        value={item.size}
                        onChange={(e) => handleChange(e, index, 3)}
                        onKeyDown={(event) =>
                          handleKeyDown(event, "size", item._id)
                        }
                        name="size"
                        // onChange={(e) => handleChange(e, rowIndex, columnKey)}
                        // onBlur={() => setEditing(false)}
                        autoFocus
                      />
                    ) : (
                      <span>{item.size}</span>
                    )}
                  </td>
                  {/* <td>
                  {editEnabled &&
                  editableCell.rowKey === `row_${index}_col_4` ? (
                    <input
                      type="number"
                      className="edit-column"
                      value={item.pack}
                      onChange={(e) => handleChange(e, index, 4)}
                      onKeyDown={(event) =>
                        handleKeyDown(event, "pack", item._id)
                      }
                      name="pack"
                      onChange={(e) => handleChange(e, rowIndex, columnKey)}
                       onBlur={() => setEditing(false)}
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={(e) => handleCellClick(e, `row_${index}_col_4`)}
                    >
                      {item.pack}
                    </span>
                  )} 
                  <span
                   onClick={(e) => handleCellClick(e, `row_${index}_col_4`)}
                  >
                    {getNumbericValue(item.pack)}
                  </span>
                </td> */}
                  {/* <td>
                  {editEnabled &&
                  editableCell.rowKey === `row_${index}_col_5` ? (
                    <input
                      type="text"
                      className="edit-column"
                      value={item.csAvail}
                      onChange={(e) => handleChange(e, index, 5)}
                      onKeyDown={(event) =>
                        handleKeyDown(event, "csAvail", item._id)
                      }
                      name="csAvail"
                      // onChange={(e) => handleChange(e, rowIndex, columnKey)}
                      // onBlur={() => setEditing(false)}
                      autoFocus
                    />
                  ) : (
                    <span
                      onClick={(e) => handleCellClick(e, `row_${index}_col_5`)}
                    >
                      {item.csAvail}
                    </span>
                  )} 
                  <span
                  // onClick={(e) => handleCellClick(e, `row_${index}_col_5`)}
                  >
                    {getNumbericValue(item.csAvail)}
                  </span>
                </td> */}
                  <td>
                    <div
                      onClick={() => handleOfferManagement(item)}
                      className={styles.statusContainer}
                    >
                      <span
                        key={index}
                        className={`fw-500 pe-cursor ${styles.status} ${styles["view-offers"]}`}
                      >
                        View Offers
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ bottom: "-70px" }} className="position-absolute w-100">
          <Paginate
            currentPage={page}
            pageInfo={pageInfo}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handlePerPage={handlePerPage}
            resetPage={handleResetPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCatalogue;
