import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function Sitemap() {
  const location = useLocation();
  const page = useSelector((state) => state.page);

  const handleOfferManagementClick = (event) => {
    event.preventDefault();
    if (
      location.pathname.endsWith("offer-management") ||
      location.pathname.endsWith("offer-management/")
    ) {
      return;
    } else {
      window.history.back();
    }
  };

  return (
    <div
      style={{
        marginTop: "12px",
        marginBottom: "-32px",
      }}
      className="ml-5rem"
    >
      {/* <h2>Sitemap</h2> */}

      {location?.pathname.includes("offer-management") && (
        // (
        //   <ul style={{ listStyle: "none", display: "flex", gap: "4px" }}>
        //     {/* <li>
        //       <Link to="/">Home</Link>
        //     </li>
        //     {" > "} */}
        //     <li>
        //       <Link
        //         to={location.pathname}
        //         onClick={(event) => handleOfferManagementClick(event)}
        //       >
        //         {location.pathname.split("/").pop()}
        //       </Link>
        //     </li>
        //   </ul>
        // ) : (
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            gap: "4px",
            fontSize: "13px",
          }}
        >
          {page?.previousPage === "Product Offer" &&
            page?.cameFrom !== "Dashboard" && (
              <>
                <li>
                  <Link to="/product-catalogue">Product Catalogue</Link>
                </li>
                {" > "}
              </>
            )}

          {page?.cameFrom === "Dashboard" && (
            <>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              {" > "}
            </>
          )}

          <li>
            <Link
              to="/offer-management"
              onClick={(event) => handleOfferManagementClick(event)}
            >
              {page?.previousPage}
            </Link>
          </li>

          {!location.pathname.endsWith("offer-management") &&
            !location.pathname.endsWith("offer-management/") && (
              <>
                {" > "}

                <li>
                  <Link to={location.pathname}>Offer Management</Link>
                </li>
              </>
            )}
        </ul>
      )}

      {location?.pathname.includes("supplier-informations") && (
        // (
        //   <ul style={{ listStyle: "none", display: "flex", gap: "4px" }}>
        //     {/* <li>
        //       <Link to="/">Home</Link>
        //     </li>
        //     {" > "} */}
        //     <li>
        //       <Link
        //         to={location.pathname}
        //         onClick={(event) => handleOfferManagementClick(event)}
        //       >
        //         {location.pathname.split("/").pop()}
        //       </Link>
        //     </li>
        //   </ul>
        // ) : (
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            gap: "4px",
            fontSize: "13px",
          }}
        >
          <li>
            <Link to="/supplier-informations">Supplier Informations</Link>
          </li>
          {!location.pathname.endsWith("supplier-informations") &&
            !location.pathname.endsWith("supplier-informations/") && (
              <>
                {" > "}
                <li>
                  <Link to={location.pathname}>
                    {location.pathname.split("/").pop()}
                  </Link>
                </li>
              </>
            )}
        </ul>
      )}
      {/* Add links to other pages of your application */}

      {/* <Button onClick={() => window.history.back()}>Go Back</Button> */}
    </div>
  );
}

export default Sitemap;
