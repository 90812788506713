import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import NotesModal from "./UI/NotesModal";
import { useLocation, useNavigate } from "react-router-dom";
import Paginate from "./UI/Pagination";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../_store/reducers/loaderReducer";
import { notifyError, notifyInfo, notifySuccess } from "./helper/Toaster";
import {
  formatDate,
  getAbvValue,
  getDollarValue,
  getEuroValue,
  getPoundValue,
  numbersFormatter,
} from "./helper/formatters";
import Notifications from "./common/Notifications";
import _ from "lodash";
import SelectComponent from "./common/SelectComponent";
import ReactDatePicker from "react-datepicker";
import {
  cameFrom,
  setPage as setPageAction,
} from "../_store/reducers/pageReducer";

// STYLES CSS
import styles from "../_css/OfferManagement.module.css";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "react-toggle";
import {
  getOfferManagementS1,
  offerMgmtEdit,
  offerMgmtS1Action,
  offerMgmtS1Search,
} from "../requests";
import { TOKEN_ERRORS } from "../_data/codes";
import handleSessionClear from "./helper/handleSessionClear";
import {
  coloumns,
  salsesColumns,
  sortableColumns,
  widthColoumns,
} from "../_data/tableHeaders";
import useViewportHeight from "./hooks/useViewPortHeight";
import { isValidMoneyNumber } from "./helper/validators";

const OfferManagement = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { percentageHeight } = useViewportHeight(65);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    totalPages: 1,
    totalPosts: 0,
  });
  const [editableCell, setEditableCell] = useState({
    rowKey: null,
  });
  const [editEnabled, setEditEnabled] = useState(false);
  const [options, setOptions] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [sortingData, setSortingData] = useState(null);
  const [loadingSuggestion, setLoadingSuggestion] = useState(false);
  const [conversionRate, setConversionRate] = useState({
    dollarForEuro: 0,
    dollarForPound: 0,
    euroForDollar: 0,
    euroForPound: 0,
    poundForDollar: 0,
    poundForEuro: 0,
  });
  const [perPage, setPerPage] = useState(5);
  const [dataSorting, setDataSorting] = useState({
    date: -1,
  });
  const [emailContent, setEmailContent] = useState("");

  const itemName = location.state?.itemName;
  const size = location.state?.size;
  const offer_id = location.state?.offer_id;
  const cameFromAlias = location.state?.cameFrom;

  if (!itemName && !offer_id) Navigate("/product-catalogue");

  const access_token = localStorage.getItem("access_token_MN");

  const isAdmin =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 2;
  const isSales =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 3;

  useEffect(() => {
    if (access_token) {
      const payload = {
        currentPage: "Product Offer",
        previousPage: "Product Offer",
      };
      dispatch(setPageAction(payload));
      dispatch(cameFrom({ cameFrom: cameFromAlias }));
      getOffers();
    } else {
      Navigate("/login");
    }
  }, [page, perPage, sortingData, dataSorting]);

  const handleDateFilter = async (selectedDateRange) => {
    setDateRange(selectedDateRange);

    const [startDate, endDate] = selectedDateRange;

    if (startDate && endDate) {
      setSortingData((prevValue) => {
        return { ...prevValue, column2: "date", value2: selectedDateRange };
      });
      handleResetPage();
    } else if (!startDate && !endDate) {
      setSortingData({
        column: sortingData?.column,
        value: sortingData?.value,
      });
      handleResetPage();
    }
  };

  const getOffers = async () => {
    // CHECK IF FILTER IS APPLIED
    let isSorting = false;
    if (sortingData) {
      isSorting = true;
    }

    dispatch(startLoader());

    try {
      const payload = {
        limit: perPage || 5,
        size: size || "",
        page,
        itemName,
        size,
        isSorting,
        sortingData,
        dataSorting,
        offer_id,
      };

      const response = await getOfferManagementS1(payload);

      if (response.status === 200) {
        const pageInfo = {
          totalPages: response.data.totalPages || 1,
          totalPosts: response.data.totalProducts || 0,
        };

        setData(response?.data?.products);
        setConversionRate(response?.data?.conversionRate);
        setPageInfo(pageInfo);
        // setAuthFalse();
      } else if (response.status === 204) {
        setData([]);
        setPageInfo({
          totalPages: 1,
          totalPosts: 0,
        });
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleSort = (key) => {
    let lowerKey = _.toLower(key);

    if (lowerKey === "date") lowerKey = "date";
    if (lowerKey === "btls avail") lowerKey = "btlsAvail";
    if (lowerKey === "cs avail") lowerKey = "csAvail";
    if (lowerKey === "price per case(€)") lowerKey = "pricePerCaseEuro";
    if (lowerKey === "price per bottle(€)") lowerKey = "pricePerBottleEuro";

    setDataSorting((prevValue) => ({
      [lowerKey]: prevValue[lowerKey] === 1 ? -1 : 1,
    }));
    // let lowerKey = _.toLower(key);
    // let previousLowerKey = !_.isEmpty(sortedColumn.column)
    //   ? _.toLower(sortedColumn.column)
    //   : null;

    // if (lowerKey === "btls avail") lowerKey = "btlsAvail";
    // if (previousLowerKey === "btls avail") previousLowerKey = "btlsAvail";
    // if (lowerKey === "cs avail") lowerKey = "csAvail";
    // if (previousLowerKey === "cs avail") previousLowerKey = "csAvail";
    // if (lowerKey === "price per case(€)") lowerKey = "pricePerCaseEuro";
    // if (previousLowerKey === "price per case(€)")
    //   previousLowerKey = "pricePerCaseEuro";
    // if (lowerKey === "price per bottle(€)") lowerKey = "pricePerBottleEuro";
    // if (previousLowerKey === "price per bottle(€)")
    //   previousLowerKey = "pricePerBottleEuro";

    // let sortedData = data;
    // let coloumnChanged = false;

    // if (previousLowerKey && lowerKey !== previousLowerKey) {
    //   coloumnChanged = true;
    //   sortedData = [...data].sort((a, b) => {
    //     if (a[previousLowerKey] < b[previousLowerKey])
    //       return sortedColumn.sortDsc ? -1 : 1;
    //     if (a[previousLowerKey] > b[previousLowerKey])
    //       return sortedColumn.sortDsc ? 1 : -1;
    //     return 0;
    //   });
    // }

    // const sortingBasis = coloumnChanged ? coloumnChanged : sortedColumn.sortDsc;
    // sortedData = [...sortedData].sort((a, b) => {
    //   if (a[lowerKey] < b[lowerKey]) return sortingBasis ? -1 : 1;
    //   if (a[lowerKey] > b[lowerKey]) return sortingBasis ? 1 : -1;
    //   return 0;
    // });

    // setSortedColumn({ column: key, sortDsc: !sortingBasis });
    // setData(sortedData);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleResetPage = () => {
    setPage(1);
  };

  const handleNext = async (page) => {
    if (page + 1 <= pageInfo.totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = async (page) => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };

  const handlePerPage = (count) => {
    setPerPage(count);
  };

  // GET SUGGESTION DATA FOR FILTER
  const getSuggestionData = async (inputColumn, inputValue) => {
    if (_.isEmpty(inputColumn) && _.isEmpty(inputValue)) return;
    setOptions([]);

    setLoadingSuggestion(true);
    try {
      const payload = {
        searchFor: inputColumn,
        searchString: inputValue,
        size,
        itemName,
      };
      const response = await offerMgmtS1Search(payload);

      if (response.status === 200) {
        const { formattedData } = response.data;

        setOptions(formattedData);
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      setLoadingSuggestion(false);
    }
  };

  const handleValueChange = async (value, column) => {
    if (value && value?.length > 0 && column) {
      let values = [];
      for (const data of value) {
        values.push(data.value);
      }
      const reqData = {
        value: values,
        column: column,
      };
      setSortingData(reqData);
      // await getOffers(true, reqData);
    } else {
      setSortingData(null);
    }
    handleResetPage();
  };

  const clearValueHandler = () => {
    handleResetPage();
    setOptions([]);
    setSortingData(null);
    setDateRange([null, null]);
  };

  const handleShowModal = (item) => {
    const reqData = {
      action: "Request",
      data: item,
    };

    setSelectedAction(reqData);
    setShowModal(!showModal);
  };

  // SALES PERSON ADDS REQUST NOTES UPDATED IN DATABASE
  const submitRequest = async (note, actionData) => {
    dispatch(startLoader());

    try {
      const { action, data } = actionData;
      const quantity = actionData?.quantity;

      const payload = {
        actionType: action,
        note: note,
        productId: data._id,
        quantity: quantity,
      };
      const response = await offerMgmtS1Action(payload);
      if (response.status === 200) {
        Navigate(`/offer-management/${data._id}`, {
          state: { element_id: data._id, lastPage: "Product Offer" },
        });
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleContinue = async (note, action) => {
    setShowModal(!showModal);
    // set notes to table
    await submitRequest(note, action);
  };

  const onStatusClickHandler = async (item) => {
    if (isSales && item?.notes.length < 1) {
      handleShowModal(item);
    } else {
      Navigate(`/offer-management/${item._id}`, {
        state: { element_id: item._id, lastPage: "Product Offer" },
      });
    }
  };
  // const filteredData = data.filter(
  //   (item) =>
  //     item.name.toLowerCase().includes(search.toLowerCase()) ||
  //     item.category.toLowerCase().includes(search.toLowerCase()) ||
  //     item.brand.toLowerCase().includes(search.toLowerCase())
  // );

  // EDITABLE COLUMN CODE STARTS HERE
  const handleCellClick = (e, key) => {
    e.preventDefault();
    const column = {
      rowKey: key,
    };
    if (isAdmin) setEditableCell(column);
  };

  const handleCellChange = (e, rowIndex) => {
    const newData = [...data];
    const { name, value } = e.target;
    newData[rowIndex][name] = value;
    setData(newData);
  };

  const handleKeyDown = (event, column, itemId) => {
    if (event.key === "Enter") {
      const value = event.target.value;
      if (column === "pricePerCaseEuro" || column === "pricePerBottleEuro") {
        if (!isValidMoneyNumber(value)) {
          notifyError("Not a Valid Entry!");
          return;
        }
      }
      // Call the function when Enter is pressed
      handleCellUpdate(column, value, itemId);
    } else if (event.key == "Escape") {
      console.log("esc clicked");
    }
  };

  const handleCellUpdate = async (column, value, offerId) => {
    if (_.isEmpty(column) || _.isEmpty(value) || _.isEmpty(offerId)) return;
    dispatch(startLoader());

    try {
      const payload = {
        offerId,
        column,
        value,
      };

      const response = await offerMgmtEdit(payload);
      if (response.status === 200) {
        notifySuccess(response.data.message);
        // setAuthFalse();
      }
    } catch (err) {
      notifyError(err.response.data.message);
      if (TOKEN_ERRORS.includes(err.response.status)) {
        handleSessionClear();
        Navigate("/");
      }
    } finally {
      dispatch(stopLoader());
    }
  };

  const setEdit = (event) => {
    if (event.target.checked) {
      setEditEnabled(true);
    } else {
      setEditEnabled(false);
    }
  };

  const onFocusHandler = async (column) => {
    await getSuggestionData(column);
    // if (column === "size") {
    //   if (!sortingData?.value || _.isEmpty(sortingData?.value)) {
    //     notifyInfo("Please select Description First!");
    //     return;
    //   }
    //   await getSuggestionData("size", alcoholInfo?.name, true);
    // } else if (column === "name") {
    //   await getSuggestionData(column);
    // }
  };

  const getNumbericValue = (value) => {
    if (!value) {
      return "-";
    } else if (typeof value === "object") {
      return value.$numberDecimal;
    } else {
      return value;
    }
  };

  const getCheveron = (key) => {
    let lowerKey = _.toLower(key);

    if (lowerKey === "date") lowerKey = "date";
    if (lowerKey === "btls avail") lowerKey = "btlsAvail";
    if (lowerKey === "cs avail") lowerKey = "csAvail";
    if (lowerKey === "price per case(€)") lowerKey = "pricePerCaseEuro";
    if (lowerKey === "price per bottle(€)") lowerKey = "pricePerBottleEuro";

    return (
      <i>
        {Object.keys(dataSorting)[0] === lowerKey ? (
          dataSorting[lowerKey] === -1 ? (
            <ChevronUp size={16} />
          ) : (
            <ChevronDown size={16} />
          )
        ) : (
          <ChevronUp size={16} />
        )}
      </i>
    );
  };

  const handleShowEmail = (item) => {
    const emailHtml = item?.rawEmail;
    if (!emailHtml) {
      notifyInfo("Email is not available");
      return;
    }
    setEmailContent(emailHtml);
  };

  return (
    <div className={`container ml-5rem mt-4 ${styles.productCatalogue}`}>
      <div
        className={`position-relative ${styles["responsive-width"]} ${styles.filterSection}`}
      >
        <div className={`${styles.container}`}>
          <div className={`${styles.header} d-flex flex-row`}>
            <div className="col-2">
              <h1 className={styles.title}>Product Offer</h1>
            </div>
            <div className={`${styles.filters} col-10`}>
              {isAdmin && (
                <div className="d-flex fs-13 gap-1">
                  <label className="react-toggle-label" htmlFor="edit">
                    Edit Table
                  </label>
                  <Toggle
                    id="edit"
                    defaultChecked={editEnabled}
                    onChange={(event) => setEdit(event)}
                    icons={false}
                  />
                </div>
              )}
              <SelectComponent
                options={options}
                placeholder={"Type"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={onFocusHandler}
                inputColumn={"giftBoxOrNoGiftBox"}
                loadingSuggestion={loadingSuggestion}
              />
              <SelectComponent
                options={options}
                placeholder={"Variant"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={onFocusHandler}
                inputColumn={"refNref"}
                loadingSuggestion={loadingSuggestion}
              />
              <SelectComponent
                options={options}
                placeholder={"Coded"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={onFocusHandler}
                inputColumn={"codedOrDecoded"}
                loadingSuggestion={loadingSuggestion}
              />
              <SelectComponent
                options={options}
                placeholder={"Location"}
                handleValueChange={handleValueChange}
                getSuggestionData={getSuggestionData}
                clearValueHandler={clearValueHandler}
                onFocusHandler={onFocusHandler}
                inputColumn={"location"}
                loadingSuggestion={loadingSuggestion}
              />

              <div>
                <ReactDatePicker
                  showIcon
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    handleDateFilter(update);
                  }}
                  placeholderText="Date"
                  style={{ width: "20px" }}
                  isClearable={true}
                  dateFormat={"dd/MM/yy"}
                />
              </div>
              {/* <div className={styles.searchContainer}>
                <Search size={16} />
                <input
                  type="text"
                  className={styles.searchInput}
                  placeholder="Search"
                />
              </div> */}
              <Notifications />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["table-wrapper"]}>
        <div className="position-relative">
          <div
            className={`${styles.table} ${styles["responsive-width-table"]} overflow-auto table-height-400`}
            style={{ maxHeight: percentageHeight }}
          >
            <table className="table">
              <thead>
                <tr>
                  {(isSales ? salsesColumns : coloumns).map((e, index) => (
                    <th
                      className={`pe-cursor text-center ${
                        e === "Enquiry Status" && "enquiry-status"
                      }`}
                      onClick={() => handleSort(e)}
                      key={index}
                      style={{
                        minWidth: widthColoumns.includes(e)
                          ? "98px"
                          : e === "Enquiry Status" || e === ""
                          ? "100px"
                          : "80px",
                        // height: e === "Enquiry Status" && "3.27rem",
                      }}
                    >
                      <div className={`${styles["col-value"]}`}>
                        <span>
                          {isSales && e === "Enquiry Status" ? "Actions" : e}
                        </span>
                        {sortableColumns.includes(e) && getCheveron(e)}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td
                      onClick={() => onStatusClickHandler(item)}
                      className="sticky-left pe-cursor"
                    >
                      {isSales ? (
                        <div className={styles.statusContainer}>
                          <span
                            className={`${styles.status} ${styles.statusRequest}`}
                          >
                            Request
                          </span>
                        </div>
                      ) : (
                        <div className={styles.statusContainer}>
                          {item.notes.length > 0 ? (
                            <span
                              className={`${styles.status} ${
                                item.notes[item.notes.length - 1].type ===
                                "Purchase"
                                  ? styles["actions-tags-purchased"]
                                  : item.notes[item.notes.length - 1].type ===
                                    "Reject"
                                  ? styles.statusRejected
                                  : item.notes[item.notes.length - 1].type ===
                                    "Request"
                                  ? styles.statusRequest
                                  : styles.statusPending
                              }`}
                            >
                              {item.notes[item.notes.length - 1].type ===
                              "Purchase"
                                ? "Purchased"
                                : item.notes[item.notes.length - 1].type ===
                                  "Reject"
                                ? "Rejected"
                                : item.notes[item.notes.length - 1].type ===
                                  "Request"
                                ? "Requested"
                                : "Pending"}
                            </span>
                          ) : (
                            <span
                              className={`${styles.status} ${styles.statusNoQuery}`}
                            >
                              No Query
                            </span>
                          )}
                        </div>
                      )}
                    </td>
                    <td>
                      <span className={`${styles["col-value"]}`}>
                        {formatDate(item.date)}
                      </span>
                    </td>
                    {/* <td>
                      <span className={`${styles["col-value"]}`}>
                        {item.mfCode}
                      </span>
                    </td> */}
                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_0`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_0` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.category}
                            onChange={(e) => handleCellChange(e, index, 0)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "category", item._id)
                            }
                            name="category"
                            autoFocus
                          />
                        ) : (
                          <span>{item.category}</span>
                        )}
                      </span>
                    </td>
                    {!isSales && (
                      <td>
                        <span className={`${styles["col-value"]}`}>
                          {/* {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_1` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.supplierName}
                            onChange={(e) => handleCellChange(e, index, 1)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "supplierName", item._id)
                            }
                            name="supplierName"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_1`)
                            }
                          >
                            {item.supplierName}
                          </span>
                        )} */}
                          <span>{item.supplierName}</span>
                        </span>
                      </td>
                    )}
                    <td
                    // onClick={(e) => handleCellClick(e, `row_${index}_col_1`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_1` ? (
                          <input
                            type="number"
                            className="edit-column"
                            value={item.sCode}
                            onChange={(e) => handleCellChange(e, index, 1)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "sCode", item._id)
                            }
                            name="sCode"
                            autoFocus
                          />
                        ) : (
                          <span>{item.sCode}</span>
                        )}
                      </span>
                    </td>

                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_2`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_2` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.itemName}
                            onChange={(e) => handleCellChange(e, index, 2)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "itemName", item._id)
                            }
                            name="itemName"
                            autoFocus
                          />
                        ) : (
                          <span>{item.itemName}</span>
                        )}

                        {/* <span>{item.itemName}</span> */}
                      </span>
                    </td>
                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_3`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_3` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.pack}
                            onChange={(e) => handleCellChange(e, index, 3)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "pack", item._id)
                            }
                            name="pack"
                            autoFocus
                          />
                        ) : (
                          <span>{getNumbericValue(item.pack)}</span>
                        )}
                      </span>
                    </td>
                    <td>
                      <span className={`${styles["col-value"]}`}>
                        {/* {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_4` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.size}
                            onChange={(e) => handleCellChange(e, index, 4)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "size", item._id)
                            }
                            name="size"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_4`)
                            }
                          >
                            {item.size}
                          </span>
                        )} */}
                        <span>{item.size}</span>
                      </span>
                    </td>
                    <td
                      onClick={(e) =>
                        handleCellClick(e, `row_${index}_col_4_1`)
                      }
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_4_1` ? (
                          <input
                            type="number"
                            className="edit-column"
                            value={item?.abv}
                            onChange={(e) => handleCellChange(e, index, 4)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "abv", item._id)
                            }
                            name="abv"
                            autoFocus
                          />
                        ) : (
                          <span>{getAbvValue(item?.abv)}</span>
                        )}
                      </span>
                    </td>
                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_5`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_5` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.giftBoxOrNoGiftBox}
                            onChange={(e) => handleCellChange(e, index, 5)}
                            onKeyDown={(event) =>
                              handleKeyDown(
                                event,
                                "giftBoxOrNoGiftBox",
                                item._id
                              )
                            }
                            name="giftBoxOrNoGiftBox"
                            autoFocus
                          />
                        ) : (
                          <span>{item.giftBoxOrNoGiftBox}</span>
                        )}
                      </span>
                    </td>
                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_6`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_6` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.refNref}
                            onChange={(e) => handleCellChange(e, index, 6)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "refNref", item._id)
                            }
                            name="refNref"
                            autoFocus
                          />
                        ) : (
                          <span>{item.refNref}</span>
                        )}
                      </span>
                    </td>
                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_7`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_7` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.codedOrDecoded}
                            onChange={(e) => handleCellChange(e, index, 7)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "codedOrDecoded", item._id)
                            }
                            name="codedOrDecoded"
                            autoFocus
                          />
                        ) : (
                          <span>{item.codedOrDecoded}</span>
                        )}
                      </span>
                    </td>

                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_8`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_8` ? (
                          <input
                            type="number"
                            className="edit-column"
                            value={getNumbericValue(item.btlsAvail)}
                            onChange={(e) => handleCellChange(e, index, 8)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "btlsAvail", item._id)
                            }
                            name="btlsAvail"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_8`)
                            }
                          >
                            {getNumbericValue(item.btlsAvail)}
                          </span>
                        )}
                        {/* <span>{getNumbericValue(item.btlsAvail)}</span> */}
                      </span>
                    </td>
                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_9`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_9` ? (
                          <input
                            type="number"
                            className="edit-column"
                            value={getNumbericValue(item.csAvail)}
                            onChange={(e) => handleCellChange(e, index, 9)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "csAvail", item._id)
                            }
                            name="csAvail"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_9`)
                            }
                          >
                            {getNumbericValue(item.csAvail)}
                          </span>
                        )}
                        {/* <span>{getNumbericValue(item.csAvail)}</span> */}
                      </span>
                    </td>

                    <td
                      onClick={(e) => {
                        if (item.currency === "EUR") {
                          handleCellClick(e, `row_${index}_col_10`);
                        }
                      }}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_10` ? (
                          <input
                            type="text"
                            className="edit-column"
                            placeholder={getEuroValue(
                              getNumbericValue(item.pricePerCaseEuro),
                              item.currency,
                              conversionRate
                            )}
                            onChange={(e) => handleCellChange(e, index, 10)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "pricePerCaseEuro", item._id)
                            }
                            name="pricePerCaseEuro"
                            autoFocus
                          />
                        ) : (
                          <span>
                            {getEuroValue(
                              getNumbericValue(item.pricePerCaseEuro),
                              item.currency,
                              conversionRate
                            )}{" "}
                            {item.currency === "EUR" && "*"}
                          </span>
                        )}
                        {/* <span
                          onClick={(e) =>
                            handleCellClick(e, `row_${index}_col_10`)
                          }
                        >
                          {getEuroValue(
                            getNumbericValue(item.pricePerCaseEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span> */}
                      </span>
                    </td>
                    <td
                      onClick={(e) => {
                        if (item.currency === "USD") {
                          handleCellClick(e, `row_${index}_col_11`);
                        }
                      }}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_11` ? (
                          <input
                            type="text"
                            className="edit-column"
                            placeholder={getDollarValue(
                              getNumbericValue(item.pricePerCaseEuro),
                              item.currency,
                              conversionRate
                            )}
                            onChange={(e) => handleCellChange(e, index, 11)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "pricePerCaseEuro", item._id)
                            }
                            name="pricePerCaseEuro"
                            autoFocus
                          />
                        ) : (
                          <span
                          // onClick={(e) =>
                          //   handleCellClick(e, `row_${index}_col_11`)
                          // }
                          >
                            {getDollarValue(
                              getNumbericValue(item.pricePerCaseEuro),
                              item.currency,
                              conversionRate
                            )}{" "}
                            {item.currency === "USD" && "*"}
                          </span>
                        )}
                        {/* <span
                          onClick={(e) =>
                            handleCellClick(e, `row_${index}_col_11`)
                          }
                        >
                          {getDollarValue(
                            getNumbericValue(item.pricePerCaseEuro),
                            item.currency,
                            conversionRate
                          )}
                        </span> */}
                      </span>
                    </td>
                    <td
                      onClick={(e) => {
                        if (item.currency === "GBP") {
                          handleCellClick(e, `row_${index}_col_11_1`);
                        }
                      }}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_11_1` ? (
                          <input
                            type="text"
                            className="edit-column"
                            placeholder={getPoundValue(
                              getNumbericValue(item.pricePerCaseEuro),
                              item.currency,
                              conversionRate
                            )}
                            onChange={(e) => handleCellChange(e, index, 111)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "pricePerCaseEuro", item._id)
                            }
                            name="pricePerCaseEuro"
                            autoFocus
                          />
                        ) : (
                          <span>
                            {getPoundValue(
                              getNumbericValue(item.pricePerCaseEuro),
                              item.currency,
                              conversionRate
                            )}{" "}
                            {item.currency === "GBP" && "*"}
                          </span>
                        )}
                      </span>
                    </td>
                    <td
                      onClick={(e) => {
                        if (item.currency === "EUR") {
                          handleCellClick(e, `row_${index}_col_12`);
                        }
                      }}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_12` ? (
                          <input
                            type="text"
                            className="edit-column"
                            placeholder={getEuroValue(
                              getNumbericValue(item.pricePerBottleEuro),
                              item.currency,
                              conversionRate
                            )}
                            onChange={(e) => handleCellChange(e, index, 12)}
                            onKeyDown={(event) =>
                              handleKeyDown(
                                event,
                                "pricePerBottleEuro",
                                item._id
                              )
                            }
                            name="pricePerBottleEuro"
                            autoFocus
                          />
                        ) : (
                          <span
                          // onClick={(e) =>
                          //   handleCellClick(e, `row_${index}_col_12`)
                          // }
                          >
                            {getEuroValue(
                              getNumbericValue(item.pricePerBottleEuro),
                              item.currency,
                              conversionRate
                            )}{" "}
                            {item.currency === "EUR" && "*"}
                          </span>
                        )}
                      </span>
                    </td>
                    <td
                      onClick={(e) => {
                        if (item.currency === "USD") {
                          handleCellClick(e, `row_${index}_col_13`);
                        }
                      }}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_13` ? (
                          <input
                            type="text"
                            className="edit-column"
                            placeholder={getDollarValue(
                              getNumbericValue(item.pricePerBottleEuro),
                              item.currency,
                              conversionRate
                            )}
                            onChange={(e) => handleCellChange(e, index, 13)}
                            onKeyDown={(event) =>
                              handleKeyDown(
                                event,
                                "pricePerBottleEuro",
                                item._id
                              )
                            }
                            name="pricePerBottleEuro"
                            autoFocus
                          />
                        ) : (
                          <span
                          // onClick={(e) =>
                          //   handleCellClick(e, `row_${index}_col_13`)
                          // }
                          >
                            {getDollarValue(
                              getNumbericValue(item.pricePerBottleEuro),
                              item.currency,
                              conversionRate
                            )}{" "}
                            {item.currency === "USD" && "*"}
                          </span>
                        )}
                      </span>
                    </td>

                    <td
                      onClick={(e) => {
                        if (item.currency === "GBP") {
                          handleCellClick(e, `row_${index}_col_13_1`);
                        }
                      }}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_13_1` ? (
                          <input
                            type="text"
                            className="edit-column"
                            placeholder={getPoundValue(
                              getNumbericValue(item.pricePerBottleEuro),
                              item.currency,
                              conversionRate
                            )}
                            onChange={(e) => handleCellChange(e, index, 131)}
                            onKeyDown={(event) =>
                              handleKeyDown(
                                event,
                                "pricePerBottleEuro",
                                item._id
                              )
                            }
                            name="pricePerBottleEuro"
                            autoFocus
                          />
                        ) : (
                          <span
                          // onClick={(e) =>
                          //   handleCellClick(e, `row_${index}_col_13`)
                          // }
                          >
                            {getPoundValue(
                              getNumbericValue(item.pricePerBottleEuro),
                              item.currency,
                              conversionRate
                            )}{" "}
                            {item.currency === "GBP" && "*"}
                          </span>
                        )}
                      </span>
                    </td>
                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_14`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_14` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.status}
                            onChange={(e) => handleCellChange(e, index, 14)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "status", item._id)
                            }
                            name="status"
                            autoFocus
                          />
                        ) : (
                          <span>{item.status}</span>
                        )}
                      </span>
                    </td>

                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_15`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_15` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.leadTime || "-"}
                            onChange={(e) => handleCellChange(e, index, 15)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "leadTime", item._id)
                            }
                            name="leadTime"
                            autoFocus
                          />
                        ) : (
                          <span>{item.leadTime || "-"}</span>
                        )}
                      </span>
                    </td>

                    <td
                      onClick={(e) => handleCellClick(e, `row_${index}_col_16`)}
                    >
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_16` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.location}
                            onChange={(e) => handleCellChange(e, index, 16)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "location", item._id)
                            }
                            name="location"
                            autoFocus
                          />
                        ) : (
                          <span>{item.location}</span>
                        )}
                      </span>
                    </td>
                    {isAdmin && (
                      <td>
                        <div
                          onClick={() => handleShowEmail(item)}
                          className={styles.statusContainer}
                        >
                          <span
                            key={index}
                            className={`fw-500 pe-cursor ${styles.status} ${styles["view-offers"]}`}
                          >
                            View Email
                          </span>
                        </div>
                      </td>
                    )}
                    {/* <td>
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_17` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.onFloor}
                            onChange={(e) => handleCellChange(e, index, 17)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "onFloor", item._id)
                            }
                            name="onFloor"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_17`)
                            }
                          >
                            {item.onFloor}
                          </span>
                        )}
                      </span>
                    </td> */}
                    {/* <td>
                      <span className={`${styles["col-value"]}`}>
                        {editEnabled &&
                        editableCell.rowKey === `row_${index}_col_18` ? (
                          <input
                            type="text"
                            className="edit-column"
                            value={item.incoterms}
                            onChange={(e) => handleCellChange(e, index, 18)}
                            onKeyDown={(event) =>
                              handleKeyDown(event, "incoterms", item._id)
                            }
                            name="incoterms"
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={(e) =>
                              handleCellClick(e, `row_${index}_col_18`)
                            }
                          >
                            {item.incoterms}
                          </span>
                        )}
                      </span>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ bottom: "-70px" }} className="position-absolute w-100">
            <Paginate
              currentPage={page}
              pageInfo={pageInfo}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              handlePerPage={handlePerPage}
              resetPage={handleResetPage}
            />
          </div>
        </div>
        {emailContent && (
          <div className={styles["email-viewer"]}>
            <button
              className="btn btn-sm btn-warning"
              onClick={() => setEmailContent("")}
            >
              Close
            </button>
            <iframe width="100%" height="100%" srcDoc={emailContent}></iframe>
          </div>
        )}
        {/* {isSales && (
          <div className={styles["table-2"]}>
            <table className={`table ${styles["table-actions"]}`}>
              <thead>
                <tr className={styles["table-title-actions"]}>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {STATUS_DATA.map((value, index1) => (
                      <td key={index1}>
                        <span
                          className={`${styles["actions-tags"]} ${
                            value === "Buy"
                              ? styles["actions-tags-buy"]
                              : value === "Request"
                              ? styles["actions-tags-request"]
                              : styles["actions-tags-purchased"]
                          }`}
                          onClick={() => handleShowModal(value, index)}
                        >
                          {value}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )} */}
      </div>
      <NotesModal
        selectedAction={selectedAction}
        showModal={showModal}
        handleShowModal={handleShowModal}
        handleContinue={handleContinue}
        title={"Add Note"}
        type={isSales ? "sales" : "admin"}
      />
    </div>
  );
};

export default OfferManagement;
